/**
 *  Entrada de SCSS
 *  Esse arquivo não deve conter estilos, destinando-se apenas a importação
 *  dos arquivos de estilo
 */

@import 'https://fonts.googleapis.com/css?family=Open+Sans';

@import "../node_modules/bootstrap/scss/bootstrap";


@import "assets/scss/variables";
@import "./assets/scss/components/table";
@import "./assets/scss/components/buttons";
@import "./assets/scss/typography";
@import "./assets/scss/components/inputs";
@import "./assets/scss/dragDrop";
@import "./assets/scss/components/span";
@import "./assets/scss/components/modal";
@import "assets/scss/components/cards/custom-dg-card";
@import "./assets/scss/components/labels";
@import "./assets/scss/util";
@import "assets/scss/components/cards/card_pergunta";
@import "assets/scss/grid";


html, body {
  height: 100%;
  background-color: #f4f7ff;
  min-width: 350px !important;
}

body {
  min-width:350px;        /* Suppose you want minimum width of 1000px */
  width: auto !important;  /* Firefox will set width as auto */
}

.mat-tooltip {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.6);
}

angular-editor-toolbar {
  button i {
    font-family: FontAwesome !important;
    color: $colors-roxo !important;
  }
  ae-select {
    color: $colors-roxo !important;

    * {
      color: $colors-roxo !important;
    }
  }
}
