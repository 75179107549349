@import "../variables";

.crud-table {
  margin: 0 !important;

  thead {
    .mat-column-actions {
      background: #FAFAFA !important;
    }

    //background-color: #F1F1F9;
    th {
      color: $colors-roxo !important;
      font-weight: bold;
      font-size: 11pt;
      text-align: start;
    }
    table-colum-fixed-end, .mat-column-actions {
      align-items: center !important;
      text-align: center !important;
    }
  }

  tbody {
    td {
      text-align: start;
      padding: .8rem 0;
      color: $colors-cinza !important;
    }
    .table-colum-fixed-end, .mat-column-actions {
      align-items: center !important;
      background: #FAFAFA !important;
      min-height: 100% !important;
      height: 100% !important;
      border-bottom: none;
      text-align: center;
      padding-left: .2rem;
    }

    tr:last-child {
      border-bottom: rgba(0,0,0,.12) solid 1px;
    }


    .mat-column-actions:not(.table-colum-fixed-end){
      padding-left: .5rem;
    }

    //.mat-column-actions {
    //  align-items: center !important;
    //  background: #FAFAFA !important;
    //  min-height: 100% !important;
    //  height: 100% !important;
    //  border-bottom: none;
    //  text-align: center;
    //  padding-left: .2rem;
    //  //display: flex !important;
    //  //flex-direction: column;
    //  //justify-content: center !important;
    //}
  }

  .button-menu {
    color: white !important;
    background-color: rgba(44, 81, 110, 0.3) !important;
    align-items: center;
    display: flex;
    border-radius: .6em;
    padding: 0;
    margin: auto;


    .mat-icon {
      color: white !important;
    }
  }
}
