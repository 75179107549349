@import '../variables';
body {
  span.dg-table-span {
    padding-left: .5rem;
    padding-right: .5rem;
    color: white;
    border-radius: 15px;
    font-size: 9pt;

    &.span-success {
      background-color: $colors-verde;
    }

    &.span-warning {
      background-color: $colors-amarelo;
    }

    &.span-danger {
      background-color: $colors-vermelho;
    }

    &.span-info {
      background-color: $colors-azul;
    }
  }
}
