

.dg-auto-complete {
  .autocomplete-container {
    background: none !important;
    border: none !important;
    box-shadow: none;
  }

  input {
    background: none !important;
    border: none !important;
    box-shadow: none;
    font-size: 1.15em !important;
  }
}

.w-100 {
  width: 100%;
}

.f-bold {
  font-weight: bold;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 10000 !important;
}


.dropdown-menu {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
  min-height: 64px;
  border: none;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  *, *::before, *::after {
    box-sizing: border-box;
  }

  &.show{
    animation: fadein .3s !important; /* Padrão */
    -webkit-animation: fadein 2s; /* Webkit */
    -moz-animation: fadein 2s; /* Firefox */
    -ms-animation: fadein 2s; /* IE */
  }

  li {
    .dropdown-item {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 48px;
      height: 48px;
      padding: 0 16px;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      position: relative;
      color: rgba(0, 0, 0, 0.70);

      &:hover {
        background: none;
      }
    }
  }
}


@keyframes fadein {
  from { opacity: 0.3; }
  to { opacity: 1; } /* Padrão */
}
@-moz-keyframes fadein {
  from { opacity: 0.3; }
  to { opacity: 1; } /* Firefox */
}
@-webkit-keyframes fadein {
  from { opacity: 0.3;
    to { opacity: 1; } /* Webkit */
  }
}
@-ms-keyframes fadein {
  from { opacity: 0.3; }
  to { opacity: 1; } /* IE */
}

.swal2-warning {
  * {
    color: #f8bb86 !important;
  }
}
