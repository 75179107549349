@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.font-poppins {
  font-family: poppins, sans-serif;
}

body {
  font-family: Poppins, sans-serif !important;
  //color: $colors-cinza !important;

  p, input, select, div, table, td, .mat-menu-item, ng-autocomplete>input {
    font-family: Poppins, sans-serif !important;
    color: $colors-cinza !important;
  }
  button {
    font-family: Poppins, sans-serif !important;
    color: white !important;
  }

  h1, h2, h3, h4, h5, h6, .highcharts-title, .highcharts-label {
    font-family: 'Poppins', sans-serif !important;
  }

  .form-label, .form-check-label {
    font-family: 'Poppins', sans-serif !important;
    color: $colors-cinza !important;
    font-size: 9pt;
  }

  // CLASSES DE COR DE TEXTOS
  .color-roxo, .color-purple {
    color: $colors-roxo !important;
  }
  .color-grey {
    color: $colors-cinza !important;
  }

  .f-bold {
    font-weight: bold;
  }
}


.fs-11 {
  font-size: 11pt !important;
}
.fs-12 {
  font-size: 12pt !important;
}

.color-lightgray {
  color: #afafaf !important;
}

.color-white {
  color: white !important;
}
.color-roxo, .color-purple {
  color: $colors-roxo !important;
}

.color-warning {
  color: $colors-amarelo;
}

.color-green {
  color: $colors-verde;
}
