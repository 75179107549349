@import "../variables";

.cont-label-perguntas {
  position: relative !important;
  display: flex;
  align-items: center; // alinhamento vertical
  justify-content: flex-end; // alinhamento horizontal
  margin: 0;
  padding: 0;
  width: 5%;

  .label-pergunta {
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    background-color: $colors-roxo;
    width: 70%;
    height: 150px;
    border-radius: 0 5px 5px 0;
    left: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .35;

    p {
      color: white !important;
    }
  }
}

.status-label {
  padding: .2rem 1rem;
  border-radius: 25px;
  font-size: 9pt;
  color: white;

  &.success {
    background-color: #71BC7E;
  }
  &.primary {
    background-color: #3EBEE5;
  }
  &.purple {
    background-color: $colors-roxo;
  }
  &.danger {
    background-color: #EC536C;
  }
  &.warning {
    background-color: #F4B125;
    color: white;
  }
  &.black {
    background-color: $colors-cinza;
  }
}
