
//$colors-azu-marinho: #293243;
//$colors-roxo: #7A6FBE;
//$colors-azul: #0DCAF0;
//$colors-vermelho: #EC536C;
//$colors-cinza: #5B626B;
//$colors-amarelo: #F5B225;
//$colors-verde: #58DB83;

// CORES DE APOIO
$colors-azu-marinho: #293243;
$colors-verde: #58DB83;
// CORES DA MARCA
//$colors-roxo: #741A99;
$colors-roxo: #2c516e;
$colors-azul: #0DCAF0;
$colors-vermelho: #EF426F;
$colors-cinza: #434343;
$colors-amarelo: #FFE1AB;

/* Layouts BOOTSTRAP */
$xs-layout: 'only screen and (max-width: 575px)';
$sm-layout:'only screen and (max-width: 767px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$xg-layout : 'only screen and (min-width: 1200px)';
$xl-layout : 'only screen and (min-width: 1400px)';

/* Layouts BOOTSTRAP  MIM (minimo no inicio do grid) */
$min-md-layout:'only screen and (min-width: 768px)';
$min-lg-layout : 'only screen and (min-width: 992px)';


$max-lg-layout : 'only screen and (max-width: 992px)';
