.modal-content {
  .modal-header {
    border: none;

    .header-row-close-button {
      min-width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        background-color: #5B626B !important;
        width: 2rem;
        height: 2rem;

        &:hover {
          background-color: #6a6f77 !important;
        }

        mat-icon {
          color: white !important;
          font-size: 11pt;
        }
      }
    }
  }
}

#modalAddPerguntas {
  .modal-content {
    min-height: 95%;
  }

  .td-categorias {
    padding-bottom: .5rem;
  }

  .esconderPergunta {
    display: none;
  }

  .perguntas-table {
    tr {
      border-bottom: rgba(108,117,125,0.39) .01rem solid;
      margin: 0;

      td {
        padding-top: .7rem;
        padding-bottom: .7rem;
        margin: 0;

        &.dt-pergunta {
          text-align: left;
          width: 95%;
        }

        &.td-input {
          display: inline-flex;
          align-items: center;
        }
      }

      .label-disabled {
        color: #868686;
      }
    }
  }
}
