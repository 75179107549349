@import "../variables";

body {
  /*************************************************
      ESTILOS COMUM A TODOS OS INPUTS
   ************************************************/

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: none;
  }

  // estilo de erro ao preencher input
  input.ng-touched, select.ng-touched, ng-autocomplete.ng-touched {
    &.ng-invalid {
      border-color: red !important;
    }
  }

  // sizes de inputs
  input[type=text], input[type=password], input[type=email], input[type=data], input[type=number], select {
    &.form-control, &.form-select {
      padding: .7em 1.1em !important;
      font-size: 1.15em;
    }
  }

  /************************************************
      estilos aplicados em input's do tipo RADIO
   ***********************************************/
  input[type=radio], input[type=checkbox].dg-checkbox-as-radio {
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: none;
    border: solid 1px;
    border-radius: 50%;

    &.md {
      width: 1.25em;
      height: 1.25em;
      border: solid 2px;
    }

    &.lg {
      width: 1.5em;
      height: 1.5em;
      border: solid 2px;
    }

    &.purple {
      border-color: $colors-roxo;
      &:after {
        visibility: visible;
      }
      &:checked {
        box-shadow: none;
        border-color: $colors-roxo;
      }
      &:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        position: relative;
        background-color: $colors-roxo;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $colors-roxo;
      }
    }

    &.danger {
      border-color: $colors-vermelho;

      &:after {
        visibility: visible;
      }
      &:checked {
        box-shadow: none;
        border-color: $colors-vermelho;
      }
      &:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        position: relative;
        background-color: $colors-vermelho;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $colors-vermelho;
      }
    }

  }

  /************************************************
      estilos aplicados em input's do tipo CHECKBOX
   ***********************************************/
  input[type=checkbox] {
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: none;
    border: solid 1px;

    &.cr {
      border: solid 1px $colors-roxo;
    }

    &.md {
      width: 1.25em;
      height: 1.25em;
      border: solid 2px;
    }

    &.lg {
      width: 1.5em;
      height: 1.5em;
      border: solid 2px;
    }

    &.purple {
      border-color: $colors-roxo;
      &:after {
        visibility: visible;
      }
      &:checked {
        box-shadow: none;
        border-color: $colors-roxo;
      }
      &:checked:after {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $colors-roxo;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $colors-roxo;
      }
    }

    &.danger {
      border-color: $colors-vermelho;

      &:after {
        visibility: visible;
      }
      &:checked {
        box-shadow: none;
        border-color: $colors-vermelho;
      }
      &:checked:after {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $colors-vermelho;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $colors-vermelho;
      }
    }

  }

  /************************************************
      estilos aplicados em input's do tipo CHECKBOX
   ***********************************************/
  mat-slide-toggle {
    &.mat-checked {
      &.color-purple {
        .mat-slide-toggle-thumb {
          background-color: $colors-roxo !important;
          color: $colors-roxo !important;
        }
        .mat-slide-toggle-bar {
          background-color: rgba(44, 81, 110, 0.58) !important;
          color: rgba(44, 81, 110, 0.58) !important;
          //background-color: rgba(122, 111, 190, 0.58) !important;
          //color: rgba(122, 111, 190, 0.58) !important;
        }
      }
    }
  }


  /***********************************************
      estilos aplicados em SELECTS
   **********************************************/
  select {
    &.bg-purple {
      background: url("/assets/scss/icons/new-icon-select-3.gif") no-repeat calc(100% - 5px) center !important;

      &:disabled {
        background: url("/assets/scss/icons/new-icon-select-3.gif") no-repeat calc(100% - 5px) center !important;
        opacity: 50%;
      }
    }

    &.no-icon {
      background: none !important;
      background-color: white !important;
      -webkit-appearance: none !important;
    }

  }
}
