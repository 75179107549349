@import '../../variables';

.custom-dg-card.card-purple {
   .dg-card {
     background-color: $colors-roxo;
     color: lightgrey !important;
     border: none !important;


     .card-footer {
       border: none;
       background: none;
     }
   }

  angular-editor {
    .angular-editor-wrapper {
      color: white !important;
      * {
        color: white !important;
      }
    }
  }

  .exibi-texto-editor {
    color: white !important;
    * {
      color: white !important;
    }
  }
}

.custom-dg-card.card-grey {
   .dg-card {
     background-color: rgb(218, 218, 218);
     color: lightgrey !important;
     border: none !important;


     .card-footer {
       border: none;
       background: none;
     }
   }
}


