@import "../variables";

button.dg-btn, .dg-btn {
  padding-left: 3em !important;
  padding-right: 3em !important;
  margin: .5rem .5rem;
  transition-duration: .2s;

  &.xl {
    padding-left: 6em !important;
    padding-right: 6em !important;
  }

  &.btn-danger {
    background-color: $colors-vermelho;
    border-color: $colors-vermelho;

    &:hover {
      background-color: #fa647c !important;
      border-color: #f65771 !important;
    }
  }

  &.btn-purple {
    background-color: $colors-roxo;
    border-color: $colors-roxo;

    &:hover {
      background-color: $colors-azu-marinho !important; //#9252cb !important;
      border-color: $colors-azu-marinho !important; //#9252cb !important;
      //background-color: #897fc7 !important;
      //border-color: #897fc7 !important;
    }
  }

  &.btn-purple-outline {
    background: none;
    border-color: $colors-roxo;
    color: $colors-roxo !important;

    &:hover {
      background-color: $colors-azu-marinho !important; //#897fc7 !important;
      border-color: $colors-azu-marinho !important; //#897fc7 !important;
      color: white !important;
    }
  }

  &.btn-purple-outline-fundo-branco {
    background: none;
    border: $colors-roxo 2px solid !important;
    color: $colors-roxo !important;

    &:hover {
      background-color: white !important;
    }
  }

  &.btn-cinza {
    background-color: $colors-cinza;
    border-color: $colors-cinza;

    &:hover {
      background-color: #7e868f !important;
      border-color: #7e868f !important;
    }
  }

  &.btn-cinza-outline {
    background: none;
    border-color: $colors-cinza;
    color: $colors-cinza !important;

    &:hover {
      background-color: #7e868f !important;
      border-color: #7e868f !important;
      color: white !important;
    }
  }

}

button {
  .mat-icon {
    color: $colors-roxo !important;

    &:hover {
      color: $colors-azu-marinho !important;
    }
  }
}
