.dg-card-pergunta {
  border-radius: 10px;
  width: 100%;
  min-height: 6rem !important;
  -webkit-box-shadow: 4px 4px 10px -7px #000000;
  box-shadow: 4px 4px 10px -7px #000000;

  // estilo drag&drop
  .dragHand {
    background-color: rgba(44, 81, 110, 0.25);
    border-radius: 10px 10px 0 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  // estilo para categorias em card-footer
  .card-footer {
    height: auto !important;

    .col-categoria {
      .input-container {
        input[type=text] {
          padding-right: 10% !important;
        }
      }
      .x.ng-star-inserted {
        background-color: white;
      }
    }
  }

  // container de opções do footer
  .container-pergunta-footer {
    border-top: 1px rgba(123, 124, 126, 0.3) solid !important;

    // coluna de checkbox
    .col-checkbox {
      display: inline-flex;
      align-items: flex-end;
      justify-content: flex-start;
      //width: auto;
      white-space: nowrap !important;
    }

    // containers de icones
    .col-icons {
      display: inline-flex;
      justify-content: flex-end;
      font-size: 14pt !important;
      align-items: flex-end;

      .div-icon {
        margin-right: .5rem;
      }

      mat-icon, mat-slide-toggle {
        padding: 0;
      }
    }

    // estilo do auto-complete
    .dg-auto-complete {
      border-radius: 0;
      padding: 0;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: $colors-roxo 1px solid;
      font-size: .9em;
    }

    // mantendo aba do autocomplete acima de outros elementos
    .suggestions-container.is-visible {
      z-index: 500;
    }
  }

  // estilo de card para perguntas removidas
  &.removida {
    background-color: #c7c7c7;

    * :not(mat-slide-toggle, .status-label, .mat-slide-toggle-thumb), .card-header {
      //background-color: #c7c7c7;
    }

    input, select {
      border-color: #a4a4a4;
      background-color: #c7c7c7 !important;
    }
  }
}
