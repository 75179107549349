


/**
  Criando grid customizado que representa 1/5 da tela
  criado para suprir falta de possíbilidade de divisão
  da tela por 5 no bootstrap
 */

.col-2-4 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-2-4 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-2-4 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-2-4 {
    width: 20%;
    float: left;
  }
}
